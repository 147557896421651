export default {
  methods: {
    verifycontainercode(containerCode) {
      var valore = containerCode.toUpperCase();
      var somma = 0;
      var check = 0;
      var alfa;
      var valori;
      let nume = [10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38];
      var continuo = true;
      var posiz = 0;

      if (valore === null || valore === "") {
        return true;
      }
      if (valore.length == 11) {
        alfa = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        valori = "1234567890";
        for (let i = 0; i < 4; i++) {
          posiz = alfa.indexOf(valore.charAt(i));
          if (posiz < 0) {
            continuo = false;
            break;
          } else {
            somma = somma + nume[posiz] * (1 << i);
          }
        }
        if (continuo) {
          for (let i = 4; i < 10; i++) {
            posiz = valori.indexOf(valore.charAt(i));
            if (posiz < 0) {
              continuo = false;
              break;
            } else {
              somma = somma + (valore.charAt(i) - "0") * (1 << i);
            }
          }
        }
        if (continuo) {
          check = somma % 11;
          if (check == 10) {
            check = 0;
          }
          if (check == valore.charAt(10) - "0") {
            return true;
          }
        }
      }
      return false;
    },
  },
};
