<template>
  <div>
    <TosListVuetify serviceName="yard" entityName="units" :headers="headers" :prepareItemFunction="prepareItem">
      <template #customFilters="slotProps">
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-text-field :label="tLabel('Unit number')" v-model="slotProps.data.unitNumber"  />
            </v-col>
            <v-col cols="4">
              <v-select :label="tLabel('Unit Class')" v-model="slotProps.data.sysSubtype" :items="sysSubtypes" />
            </v-col>
            <v-col cols="4">
              <v-autocomplete :label="tLabel('Owner')" v-model="slotProps.data.ownerCompanyId" :items="owners" item-value="id" item-text="code" />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.ownerCompanyId="slotProps">
        {{ ownersMap[slotProps.data.ownerCompanyId] }}
      </template>
      <template v-slot:editF="slotProps">
        <v-container fluid>
          <v-row align="center">
            <v-col cols="2">
              <v-text-field :label="tLabelRequired('Unit Code')" clearable v-model="slotProps.data.unitNumber" :rules="[rules.required]" :error-messages="slotProps.errors.unitNumber" />
            </v-col>
            <v-col cols="2">
              <v-checkbox v-if="slotProps.data.sysSubtype == 'CTR'" label="Forza UNIT" v-model="skipCheckDigitUtiId" />
            </v-col>
            <v-col cols="4">
              <v-autocomplete :label="tLabelRequired('Owner')" v-model="slotProps.data.ownerCompanyId" :rules="[rules.required]" :items="owners" item-value="id" item-text="code" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-select
                :label="tLabelRequired('Unit Class')"
                v-model="slotProps.data.sysSubtype"
                :items="sysSubtypes"
                @change="onChangeSysSubtype(slotProps.data, slotProps.data.sysSubtype)"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                :label="tLabelRequired('Unit Type')"
                v-model="slotProps.data.unitTypeCode"
                :items="unitTypesFiltered(slotProps.data.sysSubtype)"
                item-text="unitTypeCode"
                return-object
                @change="onChangeUnitType(slotProps.data, slotProps.data.unitTypeCode)"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('ISO size type')" v-model="unitType.isoSizeType" :disabled="unitAttrsDisabled" />
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Tare Mass')" v-model.number="unitType.tareMass" :rules="[rules.numeric]" :disabled="unitAttrsDisabled" />
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Length')" v-model.number="unitType.length" :rules="[rules.numeric]" :disabled="unitAttrsDisabled" />
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Width')" v-model.number="unitType.width" :rules="[rules.numeric]" :disabled="unitAttrsDisabled" />
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Height')" v-model.number="unitType.height" :rules="[rules.numeric]" :disabled="unitAttrsDisabled" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TosListVuetify>
  </div>
</template>

<script>
import TosListVuetify from "../../../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../../../mixins/FormatsMixins";
import YardMixin from "@/mixins/yard/YardMixin";

export default {
  name: "Units",
  data() {
    return {
      sysSubtypes: [],
      unitTypes: [],
      owners: [],
      ownersMap: [],
      skipCheckDigitUtiId: false,
      unitAttrsDisabled: true,
      unitType: [],
      headers: [
        { text: "", value: "action" },
        // { text: "ID", value: "id" },
        { text: `${this.tLabel("Unit Code")}`, value: "unitNumber" },
        { text: `${this.tLabel("Unit Class")}`, value: "sysSubtype" },
        { text: `${this.tLabel("Unit Type")}`, value: "unitTypeCode" },
        // { text: `${this.tLabel("ISO size type")}`, value: "unitType.isoSizeType" },
        // { text: `${this.tLabel("Height")}`, value: "unitType.height" },
        // { text: `${this.tLabel("Width")}`, value: "unitType.width" },
        // { text: `${this.tLabel("Length")}`, value: "unitType.length" },
        // { text: `${this.tLabel("Tare Mass")}`, value: "tareMass" },
        { text: `${this.tLabel("Owner")}`, value: "ownerCompanyId" },
      ],
    };
  },
  async mounted() {
    this.sysSubtypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/unitTypes");
    this.unitTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "unit-types");
    this.owners = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
    this.owners.forEach(o => (this.ownersMap[o.id] = o.code));
  },
  methods: {
    prepareItem(item) {
      this.unitType = this.unitTypes.find(ut => ut.unitTypeCode === item.unitTypeCode) || [];
      this.skipCheckDigitUtiId=false;
    },
    validate(item,errors){
      if (item.sysSubtype == "CTR" && !this.skipCheckDigitUtiId) {
        let verificato = this.verifycontainercode(item.unitNumber);
        if (!verificato) {
          errors.add("unitNumber","UNIT non conforme");
        }
      }
    },
    onChangeUnitType(unit, unitType) {
      this.unitType = unitType;
      console.log(unitType);
      unit.tareMass = unitType.tareMass;
      unit.unitTypeCode = unitType.unitTypeCode;
      unit.unitType = unitType;
    },
    async onChangeSysSubtype(unit, sysSubtype) {
      delete unit["unitType"];
      delete unit["unitTypeCode"];
    },
    unitTypesFiltered(sysSubtype) {
      return this.unitTypes.filter(ut => ut.sysSubtype === sysSubtype);
    },
  },
  components: { TosListVuetify },
  mixins: [FormatsMixins, YardMixin],
};
</script>
